import React from 'react';
import { Icon } from '@jvs-group/jvs-mairistem-composants';
import { ConditionVersement } from '@/src/interfaces/conditionVersement';
import ConditionVersementEtat from '@/src/constants/ConditionVersementEtat';

const renderEtatIcons = (condition: ConditionVersement) => {
  const iconsName = [];
  if (condition.etat === ConditionVersementEtat.VERSEMENT_A_DEMANDER
      || condition.etat === ConditionVersementEtat.VERSEMENT_DEMANDE) {
    // Ajout de l'icon demandé ou à demander
    if (condition.etat === ConditionVersementEtat.VERSEMENT_A_DEMANDER) {
      iconsName.push(
        {
          name: 'Pointer',
          text: 'Les conditions sont remplies : vous pouvez préparer la demande.',
          color: '#22c304',
        },
      );
    } else {
      iconsName.push(
        {
          name: 'BadgeCheck',
          text: 'Demande déjà préparée.',
          color: '#7f7f7f',
        },
      );
    }

    // Vérification des dates
    const today = new Date();
    if (condition?.dateButoire && new Date(condition.dateButoire) < today) {
      iconsName.push(
        {
          name: 'CircleX',
          text: 'Hors délai, la date butoir fixée par le financeur est dépassée.',
          color: '#c30424',
        },
      );
    } else if (condition?.dateAlerte && new Date(condition.dateAlerte) <= today) {
      iconsName.push(
        {
          name: 'BellRing',
          text: 'En alerte, la date renseignée dans les modalités d’attribution est dépassée.',
          color: '#da9335',
        },
      );
    }
  } else if (condition.etat === ConditionVersementEtat.HORS_DELAIS
      || condition.etat === ConditionVersementEtat.EN_ALERTE) {
    // Ajout de l'icon hors délai ou en alerte
    if (condition.etat === ConditionVersementEtat.HORS_DELAIS) {
      iconsName.push(
        {
          name: 'CircleX',
          text: 'Hors délai, la date butoir fixée par le financeur est dépassée.',
          color: '#c30424',
        },
      );
    } else {
      iconsName.push(
        {
          name: 'BellRing',
          text: 'En alerte, la date renseignée dans les modalités d’attribution est dépassée.',
          color: '#da9335',
        },
      );
    }
    // Ajout de l'icon condition non remplies
    iconsName.push(
      {
        name: 'Loader',
        text: 'Les conditions ne sont pas remplies pour pouvoir préparer la demande.',
        color: '#80d9de',
      },
    );
  } else {
    // Ajout de l'icon condition non remplies
    iconsName.push(
      {
        name: 'Loader',
        text: 'Les conditions ne sont pas remplies pour pouvoir préparer la demande.',
        color: '#80d9de',
      },
    );
  }

  return (
    <>
      {iconsName.map((icon) => (
        <span title={icon.text} key={icon.name}>
          <Icon
            size={30}
            name={icon.name}
            className="etatIcons"
            iconSet="Lucide"
            color="white"
            style={{ backgroundColor: icon.color }}
          />
        </span>
      ))}
    </>
  );
};

export default renderEtatIcons;
